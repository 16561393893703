import React from "react"

import Layout from "../components/Layout"
import LoadingScreen from "../components/LoadingScreen"
import SEO from "../components/Seo"

const IndexPage = () => (
  <Layout>
    <SEO title={"Home"}/>
    <LoadingScreen />
  </Layout>
)

export default IndexPage
